// Aqui se centralizan los strings de las rutas al API
export class API {
    public static webApiBase = process.env.VUE_APP_ROOT_API;
    public static webApiBaseImages = process.env.VUE_APP_ROOT_PATH+'Images/';
    public static login = API.webApiBase + 'Login';
    public static usuario = API.webApiBase + 'usuarios';
    public static roles = API.webApiBase + 'Roles';
    public static VersionBack = API.webApiBase + 'VersionBack';
    /*************************** */
    public static servidor_smtp = API.webApiBase + 'servidor_smtp';
    public static SendingEmails = API.webApiBase + 'SendingEmails';
    public static cambioscontrasenasperdidas = API.webApiBase + 'cambioscontrasenasperdidas';
    public static Connect = API.webApiBase + 'Connect';
    public static permiso = API.webApiBase + 'permiso';
    public static rol = API.webApiBase + 'roles';
    public static pantalla_lista = API.webApiBase + 'pantalla_lista';
    public static dashboard = API.webApiBase + 'dashboard';
    public static sensor = API.webApiBase + 'sensores';
    public static dispositivo = API.webApiBase + 'dispositivo';
    public static recurso_data = API.webApiBase + 'recurso_data';
    public static grupo_dashboard = API.webApiBase + 'grupo_dashboard';
    public static public = API.webApiBase + 'public';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';
    //public static Licencia = API.webApiBase + 'Licencia';



}
